define("ember-modal-dialog/templates/components/in-place-dialog", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class={{concat this.containerClassNamesString " " this.attachmentClass " " this.containerClass}}
  >
    {{yield}}
  </div>
  
  */
  {
    "id": "Kzes2A7b",
    "block": "[[[10,0],[15,0,[28,[37,0],[[30,0,[\"containerClassNamesString\"]],\" \",[30,0,[\"attachmentClass\"]],\" \",[30,0,[\"containerClass\"]]],null]],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"concat\",\"yield\"]]",
    "moduleName": "ember-modal-dialog/templates/components/in-place-dialog.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});