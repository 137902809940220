define("ember-file-upload/components/base-component", ["exports", "@ember/component", "@ember/service", "@ember/object"], function (_exports, _component, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    fileQueue: (0, _service.inject)(),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if ((0, _object.get)(this, 'queue')) {
        (0, _object.setProperties)((0, _object.get)(this, 'queue'), (0, _object.getProperties)(this, 'accept', 'multiple', 'disabled', 'onfileadd'));
      }
    },
    queue: (0, _object.computed)('name', {
      get: function get() {
        var queueName = (0, _object.get)(this, 'name');

        if (queueName != null) {
          var queues = (0, _object.get)(this, 'fileQueue');
          return queues.find(queueName) || queues.create(queueName);
        }
      }
    })
  });

  _exports.default = _default;
});