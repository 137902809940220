define("ember-sortable/templates/components/sortable-handle", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "L8QuJKK3",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-sortable/templates/components/sortable-handle.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});